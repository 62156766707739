import { materialListService, supplierService, unitsMeasureService } from '@/api/';
import { TagsViewModule } from '@/store/modules/tags-view';
import { messageError, translation } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import {
  SelectUnitMeasure,
  SelectWarehouse,
  SelectMaterialClassify,
  SelectInventoryType,
  SelectStoreLocation
} from '@/views/dialogs';

import { Form, Message } from 'element-ui';
import { ClassifyList, InventoryTypeList, MaterialSource, SupplierResource } from '@/resource/model';
import { cloneDeep } from 'lodash-es';
import AddProduct from './add-product/add-product.vue';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'AddMaterial',
  components: {
    SelectUnitMeasure,
    SelectWarehouse,
    SelectMaterialClassify,
    SelectInventoryType,
    SelectStoreLocation,
    AddProduct
  }
})
export default class AddMaterial extends Vue {
  /**
   *  物料ID
   */
  public id: number | null = null;
  public warehouseId: number = 0;

  public type = this.$route.query.type;

  public submitLoading: boolean = false;
  public loading: boolean = false;
  public unitsMeasureDialogVisible: boolean = false;
  public warehousDialogVisible: boolean = false;
  public storeDialogVisible: boolean = false;
  public materialClassifyDialogVisible: boolean = false;
  public inventoryTypDialogVisible: boolean = false;
  public generateNameDialogVisible: boolean = false;

  public valueType: string = '';

  public attributeOptions: Array<{ label: string; value: string }> = [];
  public dateOptions: Array<{ label: string; value: string }> = [
    {
      label: '日',
      value: 'DAY'
    },
    {
      label: '月',
      value: 'MONTH'
    },
    {
      label: '年',
      value: 'YEAR'
    }
  ];
  public suppliers: Array<SupplierResource> = [];

  public form: MaterialSource = {
    id: undefined,
    name: '',
    code: '',
    specification: '',
    categoryId: undefined,
    categoryName: '',
    baseUnit: undefined,
    baseUnitName: '',
    brand: '',
    attribute: 'SELF_CONTROL',
    sizeUnit: undefined,
    sizeUnitName: '',
    length: 0,
    width: 0,
    height: 0,
    weightUnit: undefined,
    weightUnitName: '',
    grossWeight: 0,
    netWeight: 0,
    volume: 0,
    inventoryTypeId: undefined,
    inventoryTypeName: '',
    remark: '',
    taxRate: 13,
    saleFlag: 1,
    purchaseFlag: 1,
    inventoryFlag: 1,
    productionFlag: 1,
    outsourceFlag: 1,
    inventory: {
      inventoryUnit: undefined,
      inventoryUnitName: '',
      adjunctUnit: undefined,
      adjunctUnitName: '',
      inventoryThresholdFlag: 0,
      inventoryThreshold: 0,
      effectiveFlag: 0,
      effectiveUnit: 'DAY',
      effectiveNum: 0,
      effectiveThresholdFlag: 0,
      leadTimeUnit: 'DAY',
      effectiveThresholdNum: 0,
      warehouseId: undefined,
      warehouseName: '',
      storeId: undefined,
      storeName: ''
    },
    sale: {
      salePriceUnit: undefined,
      salePriceUnitName: '',
      saleUnit: undefined,
      saleUnitName: ''
    },
    purchase: {
      purchasePriceUnit: undefined,
      purchasePriceUnitName: '',
      purchaseUnit: undefined,
      purchaseUnitName: '',
      supplierId: undefined,
      supplierName: ''
    },
    production: {
      productionUnit: undefined,
      productionUnitName: '',
      productionStoreId: undefined,
      productionStoreName: '',
      productionWarehouseId: undefined,
      productionWarehouseName: '',
      backendCrafts: '',
      prepressFlag: 0,
      printingFlag: 1,
      backendFlag: 1
    },
    citeCount: 0,
    weightPrecision: 6,
    sizePrecision: 6
  };
  public defaultForm: MaterialSource | null = null;
  public formRules = {
    code: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          materialListService
            .checkCode(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          materialListService
            .checkName(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.regionNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    categoryId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('materialList.category')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    baseUnit: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('materialList.baseUnit')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    attribute: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('materialList.attribute')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    inventoryTypeId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('materialList.inventoryType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    productionFlag: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (
            value === 1 &&
            !this.form.production.backendFlag &&
            !this.form.production.printingFlag &&
            !this.form.production.prepressFlag
          ) {
            callback(new Error(translation('materialList.productionFlagTip')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    inventory: {
      inventoryUnit: [
        {
          required: true,
          validator: (rule: any, value: string, callback: Function): void => {
            if (!value) {
              callback(new Error(translation('common.select') + translation('materialList.inventoryUnit')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ],
      effectiveFlag: [
        {
          required: true,
          validator: (rule: any, value: number, callback: Function): void => {
            if (value < 0) {
              callback(new Error(translation('common.select') + translation('materialList.effectiveFlag')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ],
      effectiveThresholdFlag: [
        {
          required: true,
          validator: (rule: any, value: number, callback: Function): void => {
            if (value < 0) {
              callback(new Error(translation('common.select') + translation('materialList.effectiveThresholdFlag')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ],
      inventoryThresholdFlag: [
        {
          required: true,
          validator: (rule: any, value: number, callback: Function): void => {
            if (value < 0) {
              callback(new Error(translation('common.select') + translation('materialList.inventoryThresholdFlag')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ],
      inventoryThreshold: [
        {
          required: true,
          validator: (rule: any, value: number, callback: Function): void => {
            console.log(value <= 0);
            if (!value && value !== 0) {
              callback(new Error(translation('common.input') + translation('materialList.inventoryThreshold')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ]
      // effectiveUnit: [
      //   {
      //     required: true,
      //     validator: (rule: any, value: string, callback: Function): void => {
      //       if (!value) {
      //         callback(new Error(translation('common.input') + translation('materialList.effectiveUnit')));
      //         return;
      //       }
      //       callback();
      //     },
      //     trigger: 'blur'
      //   }
      // ],
      // leadTimeUnit: [
      //   {
      //     required: true,
      //     validator: (rule: any, value: string, callback: Function): void => {
      //       if (!value) {
      //         callback(new Error(translation('common.input') + translation('materialList.leadTimeUnit')));
      //         return;
      //       }
      //       callback();
      //     },
      //     trigger: 'blur'
      //   }
      // ]
    },
    sale: {
      saleUnit: [
        {
          required: true,
          validator: (rule: any, value: string, callback: Function): void => {
            if (!value) {
              callback(new Error(translation('common.select') + translation('materialList.saleUnit')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ],
      salePriceUnit: [
        {
          required: true,
          validator: (rule: any, value: string, callback: Function): void => {
            if (!value) {
              callback(new Error(translation('common.select') + translation('materialList.priceUnit')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ]
    },
    purchase: {
      purchaseUnit: [
        {
          required: true,
          validator: (rule: any, value: string, callback: Function): void => {
            if (!value) {
              callback(new Error(translation('common.select') + translation('materialList.purchaseUnit')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ],
      purchasePriceUnit: [
        {
          required: true,
          validator: (rule: any, value: string, callback: Function): void => {
            if (!value) {
              callback(new Error(translation('common.select') + translation('materialList.priceUnit')));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }
      ]
    },
    production: {
      // productionUnit: [
      //   {
      //     required: true,
      //     validator: (rule: any, value: string, callback: Function): void => {
      //       if (!value) {
      //         callback(new Error(translation('common.select') + translation('materialList.productionUnit')));
      //         return;
      //       }
      //       callback();
      //     },
      //     trigger: 'blur'
      //   }
      // ]
    }
  };

  private get isCite(): boolean {
    return this.type === 'edit' && this.form.citeCount !== 0;
  }

  public resetForm(): void {
    (this.$refs.formRefs as Form).validate();
  }
  public openSelectUnitsMeasureDialog(value: string): void {
    this.unitsMeasureDialogVisible = true;
    this.valueType = value;
  }
  public handleSelectedUnitsMeasure(row: any): void {
    console.log('计量单位', row);
    switch (this.valueType) {
      case 'categoryId':
        this.form.categoryId = row.id;
        this.form.categoryName = row.name;
        break;
      case 'baseUnit':
        this.form.baseUnit = row.id;
        this.form.baseUnitName = row.name;
        this.changeUnit(row);
        break;
      case 'sizeUnit':
        this.form.sizeUnit = row.id;
        this.form.sizeUnitName = row.name;
        this.form.sizePrecision = row.precision;

        break;
      case 'weightUnit':
        this.form.weightUnit = row.id;
        this.form.weightUnitName = row.name;
        this.form.weightPrecision = row.precision;
        break;
      case 'inventoryTypeId':
        this.form.inventoryTypeId = row.id;
        this.form.inventoryTypeName = row.name;
        break;
      case 'inventoryUnit':
        this.form.inventory.inventoryUnit = row.id;
        this.form.inventory.inventoryUnitName = row.name;
        break;
      case 'adjunctUnit':
        this.form.inventory.adjunctUnit = row.id;
        this.form.inventory.adjunctUnitName = row.name;
        break;
      case 'saleUnit':
        this.form.sale.saleUnit = row.id;
        this.form.sale.saleUnitName = row.name;
        this.changeUnit(row);
        break;
      case 'salePriceUnit':
        this.form.sale.salePriceUnit = row.id;
        this.form.sale.salePriceUnitName = row.name;
        break;
      case 'purchaseUnit':
        this.form.purchase.purchaseUnit = row.id;
        this.form.purchase.purchaseUnitName = row.name;
        this.changeUnit(row);
        break;
      case 'purchasePriceUnit':
        this.form.purchase.purchasePriceUnit = row.id;
        this.form.purchase.purchasePriceUnitName = row.name;
        break;
      case 'supplierId':
        this.form.purchase.supplierId = row.id;
        this.form.purchase.supplierName = row.name;
        break;
      case 'productionUnit':
        this.form.production.productionUnit = row.id;
        this.form.production.productionUnitName = row.name;
        break;
    }
  }

  public openSelectWarehousDialog(value: string): void {
    this.warehousDialogVisible = true;
    this.valueType = value;
  }
  public handleSelectWarehous(row: any): void {
    switch (this.valueType) {
      case 'warehouseId':
        this.form.inventory.warehouseId = row.id;
        this.form.inventory.warehouseName = row.name;
        this.form.inventory.storeId = undefined;
        this.form.inventory.storeName = '';
        break;
      case 'productionWarehouseId':
        this.form.production.productionWarehouseId = row.id;
        this.form.production.productionWarehouseName = row.name;
        this.form.production.productionStoreId = undefined;
        this.form.production.productionStoreName = '';
        break;
    }
  }
  public openSelectStoreDialog(value: string): void {
    this.valueType = value;
    switch (this.valueType) {
      case 'storeId':
        this.warehouseId = this.form.inventory.warehouseId!;
        break;
      case 'productionStoreId':
        this.warehouseId = this.form.production.productionWarehouseId!;
        break;
    }
    if (!this.warehouseId) {
      const warehouseTip = this.valueType === 'storeId' ? 'materialList.warehouse' : 'materialList.productionWarehouse';
      Message.error(translation('common.select') + '【' + translation(`${warehouseTip}`) + '】');
    } else {
      this.storeDialogVisible = true;
    }
  }
  public handleSelectStore(row: any): void {
    switch (this.valueType) {
      case 'storeId':
        this.form.inventory.storeId = row.id;
        this.form.inventory.storeName = row.name;
        break;
      case 'productionStoreId':
        this.form.production.productionStoreId = row.id;
        this.form.production.productionStoreName = row.name;
        break;
    }
  }
  public openSelectMaterialClassifyDialog(value: string): void {
    this.materialClassifyDialogVisible = true;
    this.valueType = value;
  }
  public handleSelectMaterialClassify(row: ClassifyList): void {
    this.form.categoryId = row.id;
    this.form.categoryName = row.categoryName!;
  }
  public openSelectInventoryTypeDialog(value: string): void {
    this.inventoryTypDialogVisible = true;
    this.valueType = value;
  }
  public handleSelectInventoryType(row: InventoryTypeList): void {
    this.form.inventoryTypeId = row.id;
    this.form.inventoryTypeName = row.name!;
  }

  public changeUnit(row: any): void {
    switch (this.valueType) {
      case 'baseUnit':
        // 库存单位
        this.form.inventory.inventoryUnit = row.id;
        this.form.inventory.inventoryUnitName = row.name;
        // 销售单位
        this.form.sale.saleUnit = row.id;
        this.form.sale.saleUnitName = row.name;
        this.form.sale.salePriceUnit = row.id;
        this.form.sale.salePriceUnitName = row.name;
        // 采购单位
        this.form.purchase.purchaseUnit = row.id;
        this.form.purchase.purchaseUnitName = row.name;
        this.form.purchase.purchasePriceUnit = row.id;
        this.form.purchase.purchasePriceUnitName = row.name;
        // 生产单位
        this.form.production.productionUnit = row.id;
        this.form.production.productionUnitName = row.name;
        break;
      case 'saleUnit':
        this.form.sale.salePriceUnit = row.id;
        this.form.sale.salePriceUnitName = row.name;
        break;
      case 'purchaseUnit':
        this.form.purchase.purchasePriceUnit = row.id;
        this.form.purchase.purchasePriceUnitName = row.name;
    }
  }

  /**
   * 生成名称
   */
  public openGenerateName(): void {
    this.generateNameDialogVisible = true;
  }
  public setName(value: string, backendCrafts: string): void {
    this.form.name = value;
    this.form.production.backendCrafts = backendCrafts;
    this.generateNameDialogVisible = false;
  }

  public onSubmit(): void {
    (this.$refs.formRefs as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.type === 'add') {
          await materialListService.post({ ...this.form } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.close();
          return;
        }
        await materialListService.put({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.close();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }

  public activated(): void {
    if (this.type !== this.$route.query.type) {
      this.type = this.$route.query.type;
      Object.assign(this.form, this.defaultForm);
      this.id = 0;
      this.$nextTick(() => {
        (this.$refs.formRefs as Form).clearValidate();
      });
      this.getPriceUnits();
    }
    if (this.id !== Number(this.$route.query.id)) {
      this.type = this.$route.query.type;
      if (this.type === 'edit') {
        this.id = Number(this.$route.query.id);
        this.getDetails(this.id!);
      }
    }
  }

  public created(): void {
    this.getAttributeList();
    this.defaultForm = cloneDeep(this.form);
    this.initSuppliers();
    this.type = this.$route.query.type;
    if (this.type === 'add') {
      this.getPriceUnits();
    } else {
      if (this.id !== Number(this.$route.query.id)) {
        this.id = Number(this.$route.query.id);
        this.getDetails(this.id!);
      }
    }
  }

  public close(): void {
    Object.assign(this.form, this.defaultForm);
    this.$router.go(-1);
    TagsViewModule.DelView(this.$route);
  }

  public getAttributeList(): void {
    try {
      this.attributeOptions = materialListService.getAttributeList();
    } catch (error) {
      messageError(error);
    }
  }
  /**
   * 查询可用供应商数据
   */
  public async initSuppliers(): Promise<void> {
    try {
      this.suppliers = await supplierService.listEnable();
    } catch (error) {
      messageError(error);
    }
  }

  // public beforeRouteLeave(to: Route, from: Route, next: Function): void {
  //   TagsViewModule.DelView(this.$route);
  //   next();
  // }

  public async getPriceUnits(): Promise<void> {
    const unitOptionData = await unitsMeasureService.getPriceUnits();
    unitOptionData.forEach((item: any) => {
      const id: number = item.value;
      const name: string = item.label;
      switch (item.label) {
        case 'Pcs':
          // 基本单位
          this.form.baseUnit = id;
          this.form.baseUnitName = name;
          // 库存单位
          this.form.inventory.inventoryUnit = id;
          this.form.inventory.inventoryUnitName = name;
          this.form.inventory.adjunctUnit = id;
          this.form.inventory.adjunctUnitName = name;
          // 销售单位
          this.form.sale.saleUnit = id;
          this.form.sale.saleUnitName = name;
          this.form.sale.salePriceUnit = id;
          this.form.sale.salePriceUnitName = name;
          // 采购单位
          this.form.purchase.purchaseUnit = id;
          this.form.purchase.purchaseUnitName = name;
          this.form.purchase.purchasePriceUnit = id;
          this.form.purchase.purchasePriceUnitName = name;
          // 生产单位
          this.form.production.productionUnit = id;
          this.form.production.productionUnitName = name;
          break;
        case '米':
          this.form.sizeUnit = id;
          this.form.sizeUnitName = name;
          break;
        case '千克':
          this.form.weightUnit = id;
          this.form.weightUnitName = name;
          break;

        default:
          break;
      }
    });
  }
  private getDetails(id: number): void {
    materialListService
      .getById(id)
      .then(res => {
        Object.assign(this.form, res);
      })
      .catch(error => {
        messageError(error);
      });
  }
}
