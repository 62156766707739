import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import {
  WarehouseStoreList,
  // ExchangeRateQuery,
  ImportRes,
  WarehouseStoreQuery
} from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  translation,
  // getResourceStatusOptions,
  // getResourceApproveStatusOptions,
  // dateFormat,
  debounce,
  downloadFileByBlob,
  handleImportError
} from '@/utils';
import { Message, MessageBox } from 'element-ui';
import { ResourceStatusEnum } from '@/resource/enum';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { warehouseStoreService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging, SortOptions } from '@/api/base';
import { MessageBoxData } from 'element-ui/types/message-box';
import AddWarehouseStore from './add-warehouse-store/add-warehouse-store.vue';
import { PublicApproveStatusEnum } from '@/resource/enum/approve-status';
import { ImportFile } from '@/views/dialogs';
import { ApiResponse } from '@/api/axios';

@Component({
  name: 'WarehouseStore',
  components: { ImportFile, AddWarehouseStore }
})
export default class WarehouseStore extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<WarehouseStoreList> = {
    loading: false,
    data: [
      {
        id: 1,
        warehouseCode: 'W111',
        warehouseId: 2,
        warehouseName: '仓库名称',
        storeCode: 'S111',
        storeId: 2,
        storeName: ''
      },
      {
        id: 2,
        warehouseCode: 'W222',
        warehouseId: 1,
        warehouseName: '仓库名称',
        storeCode: 'S222',
        storeId: 1,
        storeName: ''
      },
      {
        id: 3,
        warehouseCode: 'W333',
        warehouseId: 2,
        warehouseName: '仓库名称',
        storeCode: 'S333',
        storeId: 2,
        storeName: ''
      }
    ],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<WarehouseStoreList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'warehouseCode',
      label: 'warehouseStore.warehouseCode',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'warehouseName',
      label: 'warehouseStore.warehouseName',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'storeCode',
      label: 'warehouseStore.storeCode',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'storeName',
      label: 'warehouseStore.storeName',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<WarehouseStoreQuery>> = [
    {
      type: 'Input',
      field: 'code',
      label: 'warehouseStore.warehouseCode',
      option: {
        placeholder: 'warehouseStore.warehouseCode'
      }
    },
    {
      type: 'Input',
      field: 'name',
      label: 'warehouseStore.warehouseName',
      option: {
        placeholder: 'warehouseStore.warehouseName'
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'wms:warehouseStore:save',
      handleClick: (): void => {
        this.add();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'wms:warehouseStore:delete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    // {
    //   type: 'primary',
    //   slot: 'start',
    //   label: 'unitsMeasureGroups.batchAudit',
    //   operationType: 'batchAudit',
    //   permissionCode: 'wms:warehouseStore:approve',
    //   disabled: true,
    //   handleClick: (): void => {
    //     this.batchAudit();
    //   }
    // },
    // {
    //   type: 'danger',
    //   slot: 'start',
    //   label: 'unitsMeasureGroups.batchReject',
    //   operationType: 'batchReject',
    //   permissionCode: 'wms:warehouseStore:cancelApprove',
    //   plain: true,
    //   disabled: true,
    //   handleClick: (): void => {
    //     this.batchReject();
    //   }
    // },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      permissionCode: 'wms:warehouseStore:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    }
    // {
    //   type: 'primary',
    //   slot: 'end',
    //   label: 'button.using',
    //   operationType: 'using',
    //   icon: 'el-icon-open',
    //   permissionCode: 'wms:warehouseStore:editEnableStatus',
    //   handleClick: (): void => {
    //     this.batchUpdateStatus(ResourceStatusEnum.using);
    //   },
    //   disabled: true
    // },
    // {
    //   type: 'danger',
    //   slot: 'end',
    //   plain: true,
    //   label: 'button.disabled',
    //   operationType: 'disabled',
    //   icon: 'el-icon-turn-off',
    //   permissionCode: 'wms:warehouseStore:editEnableStatus',
    //   handleClick: (): void => {
    //     this.batchUpdateStatus(ResourceStatusEnum.disabled);
    //   },
    //   disabled: true
    // }
  ];

  public dialogVisible: boolean = false;
  public importDialogVisible: boolean = false;
  public importTitle = 'database.import';

  public editRow: any | null = null;

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<WarehouseStoreList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      // {
      //   operationType: 'edit',
      //   type: 'text',
      //   label: 'button.edit',
      //   icon: 'el-icon-edit',
      //   permissionCode: 'base:exchangeRate:edit',
      //   dynamicHidden: (rowData: WarehouseStoreList): boolean => {
      //     return (
      //       rowData.enableStatus === ResourceStatusEnum.disabled ||
      //       rowData.approveStatus === PublicApproveStatusEnum.passed
      //     );
      //   },
      //   handleClick: this.edit
      // }
    ]
  };

  public selectedRows: Array<WarehouseStoreList> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private queryForm: Partial<WarehouseStoreQuery> = {
    code: '',
    name: ''
  };

  private sortOptions: SortOptions<WarehouseStoreList> = this.tableOption.defaultSort!;

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'warehouseStore');
    this.loadData();
  }

  public activated(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<WarehouseStoreList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public linkToDetails(deliveryNotice: WarehouseStoreList): void {
    // 打开详情 弹窗
    console.log('打开详情', deliveryNotice);
  }

  public handleSelectionChange(selectedData: Array<WarehouseStoreList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    let res;
    switch (status) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return res;
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return status === ResourceStatusEnum.using ? 'start-dot' : 'disabled-dot';
  }
  public getApproveStatusClass(status: PublicApproveStatusEnum): string {
    return status === PublicApproveStatusEnum.passed ? 'success-dot' : 'disabled-dot';
  }

  public getApproveStatusI18Key(status: PublicApproveStatusEnum): string {
    return 'publicApproveStatus.' + PublicApproveStatusEnum[status];
  }
  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    warehouseStoreService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<WarehouseStoreList>): void {
    this.operationOptions.forEach(x => {
      if (!['import', 'add'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  private clearSelection(): void {
    (this.$refs.warehouseStoreTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private add(): void {
    console.log('打开弹窗');
    // 新增数据
    this.dialogVisible = true;
  }
  // private edit(rowData: WarehouseStoreList): void {
  //   // 修改数据
  //   this.dialogVisible = true;
  // }

  private dialogClosed(): void {
    this.editRow = null;
  }
  private deleteConfirm(): Promise<MessageBoxData> {
    return MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    });
  }
  /**
   * 批量删除
   */
  private async batchDelete(): Promise<void> {
    const list: Array<{ storeId: number; warehouseId: number }> = this.selectedRows.map(x => {
      return { storeId: x.storeId, warehouseId: x.warehouseId };
    });

    this.deleteConfirm()
      .then(async () => {
        try {
          await warehouseStoreService.batchDelete(list);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 审核
   */
  // private async batchAudit(): Promise<void> {
  //   try {
  //     const idList: Array<number> = this.selectedRows
  //       .filter(
  //         item =>
  //           item.approveStatus === PublicApproveStatusEnum.waitApprove &&
  //           Number(item.enableStatus) === ResourceStatusEnum.using
  //       )
  //       .map(x => x.id!);
  //     if (idList.length === 0) {
  //       Message.error(translation('exchangeRate.selectWaitApproveData'));
  //       return;
  //     }
  //     await warehouseStoreService.batchApprove(idList);
  //     this.reloadData();
  //     Message.success(translation('operationRes.operationSuccess'));
  //   } catch (error) {
  //     messageError(error);
  //   }
  // }

  /**
   * 反审核
   */
  // private async batchReject(): Promise<void> {
  //   try {
  //     const idList: Array<number> = this.selectedRows
  //       .filter(
  //         item =>
  //           item.approveStatus === PublicApproveStatusEnum.passed &&
  //           Number(item.enableStatus) === ResourceStatusEnum.using
  //       )
  //       .map(x => x.id!);
  //     if (idList.length === 0) {
  //       Message.error(translation('exchangeRate.selectPassedData'));
  //       return;
  //     }
  //     await warehouseStoreService.batchCancelApprove(idList);
  //     this.reloadData();
  //     Message.success(translation('operationRes.operationSuccess'));
  //   } catch (error) {
  //     messageError(error);
  //   }
  // }

  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }
  private downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    warehouseStoreService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob('仓库仓位关系导入模板.xlsx', blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }
  private importSuccess(path: string): void {
    warehouseStoreService
      .import(path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  /**
   * 批量修改计量单位分组状态
   * @param status 状态 启用还是禁用
   */
  // private batchUpdateStatus(status: ResourceStatusEnum): void {
  //   const idList = this.selectedRows.filter(item => item.enableStatus !== status).map(x => x.id!);
  //   if (idList.length === 0) {
  //     const error =
  //       status === ResourceStatusEnum.using
  //         ? translation('common.selectDisabledData')
  //         : translation('common.selectUsingData');
  //     Message.error(error);
  //     return;
  //   }
  //   warehouseStoreService
  //     .batchCurrentUpdateStatus(idList, status)
  //     .then(() => {
  //       this.reloadData();
  //       Message.success(
  //         status === ResourceStatusEnum.using
  //           ? translation('common.usingSuccess')
  //           : translation('common.disabledSuccess')
  //       );
  //     })
  //     .catch(error => {
  //       messageError(error);
  //     });
  // }
}
