import { uniqueId } from 'lodash-es';
import { deviceClassifyService, outputService, backendService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { DeviceClassifyResource, ProductEdit } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { SelectMaterial } from '@/views/dialogs';

@Component({
  components: { SelectMaterial }
})
export default class AddProduct extends mixins(DialogMixin) {
  public isInitalized = false;
  public materialDialogVisible = false;
  public deviceClassifies: Array<DeviceClassifyResource> = [];
  public printingCraftList: Array<{ label: string; value: number }> = [];
  public backendCraftList: Array<{ label: string; value: number }> = [];

  public title = 'materialList.generateName';
  public valueType: string | null = null;
  public backendCrafts: string = '';
  public index: number = -1;

  public productForm: Partial<ProductEdit> = {
    name: '',
    deviceCategoryName: '',
    printingMaterialName: '',
    printingCraftsName: '',
    backendDataList: [this.defaultBackendData()]
  };

  public resourceFormRules = {
    deviceCategoryName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('product.selectDeviceClassify')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    printingCraftsName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('product.selectPrintingCraft')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };
  public submitLoading = false;

  public get deviceClassifyOption(): Array<DeviceClassifyResource> {
    return this.deviceClassifies;
  }

  public openSelectMaterialDialog(value: string, index: number): void {
    this.materialDialogVisible = true;
    this.valueType = value;
    this.index = index;
  }
  public handleSelectedMaterial(row: any): void {
    switch (this.valueType) {
      case 'printingMaterialName':
        this.productForm.printingMaterialName = row.name;
        break;
      case 'materialName':
        this.productForm.backendDataList![this.index].materialName = row.name;
    }
  }

  public dialogOpen(): void {
    this.init();
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.productForm as Form).resetFields();
    this.productForm.backendDataList = [this.defaultBackendData()];
  }

  public onSubmit(): void {
    (this.$refs.productForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        this.$emit('setName', this.productForm.name, this.backendCrafts);
      } catch (error) {
        messageError(error);
      } finally {
        (this.$refs.productForm as Form).resetFields();
        this.setLoading(false);
      }
    });
  }

  /**
   * 新增后道工艺+材料控件
   */
  public handleAddItem(): void {
    this.productForm.backendDataList?.push(this.defaultBackendData());
  }

  /**
   * 删除后道工艺+材料控件
   */
  public handleDeleteItem($event: Event, index: number): void {
    this.productForm.backendDataList?.splice(index, 1);
  }

  @Watch('productForm', { deep: true, immediate: true })
  public generateName(value: ProductEdit): void {
    let str = '';
    str = `${value.deviceCategoryName}${value.deviceCategoryName && value.printingCraftsName ? ' | ' : ''}${
      value.printingCraftsName
    }${value.printingCraftsName && value.printingMaterialName ? '-' : ''}${value.printingMaterialName}`;
    // 过滤空后道工艺 + 材料数据
    const backendList = [];
    for (const item of value.backendDataList ?? []) {
      if (!item.craftsName && !item.materialName) {
        continue;
      }
      const existItem = backendList.find(
        existItem => existItem.craftsName === item.craftsName && existItem.materialName === item.materialName
      );
      if (!existItem) {
        backendList.push(item);
      }
    }
    const backendStr = backendList
      .map(x => `${x.craftsName}${x.craftsName && x.materialName ? '-' : ''}${x.materialName}`)
      .join(` | `);
    if (backendList.length > 0) {
      str = str + `【${backendStr}】`;
    }
    this.backendCrafts = backendList.map(x => x.craftsName!).join(',');

    this.productForm.name = str;
  }

  /**
   * 初始化dialog所需数据
   */
  private init(): void {
    // 判断是否已初始化
    if (this.isInitalized) return;
    this.loadEnableDeviceClassifyData();
    this.loadPrintingCraftData();
    this.loadBackendCraftData();
    this.isInitalized = true;
  }

  private loadEnableDeviceClassifyData(): void {
    deviceClassifyService
      .listAllEnable()
      .then(res => {
        this.deviceClassifies = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 载入输出工艺数据
   */
  private async loadPrintingCraftData(): Promise<void> {
    try {
      const craftList = await outputService.getAllEnableList();
      this.printingCraftList = craftList.map(craft => {
        return {
          label: craft.craftsName,
          value: craft.id
        };
      });
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 载入后道工艺数据
   */
  private async loadBackendCraftData(): Promise<void> {
    try {
      const craftList = await backendService.getAllEnableList();
      this.backendCraftList = craftList.map(craft => {
        return {
          label: craft.craftsName,
          value: craft.id
        };
      });
    } catch (error) {
      messageError(error);
    }
  }

  private defaultBackendData(): { craftsName?: string; materialName?: string; key: string } {
    return {
      craftsName: '',
      materialName: '',
      key: uniqueId()
    };
  }
}
