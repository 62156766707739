import { warehouseStoreService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { WarehouseStroeResource } from '@/resource/model';

import { messageError, translation } from '@/utils';

import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { SelectStoreLocation, SelectWarehouse } from '@/views/dialogs/index';

@Component({
  name: 'AddWarehouseStore',
  components: {
    SelectStoreLocation,
    SelectWarehouse
  }
})
export default class AddWarehouseStore extends mixins(DialogMixin) {
  // @Prop({
  //   required: false,
  //   type: Object,
  //   default: () => {
  //     return null;
  //   }
  // })
  // public position!: any | null;

  public selectWarehouseVisible: boolean = false;
  public selectStoreLocationVisible: boolean = false;

  public valueType: string = '';

  public form: WarehouseStroeResource = {
    storeId: null,
    storeCode: '',
    storeName: '',
    warehouseId: null,
    warehouseCode: '',
    warehouseName: ''
  };

  public resourceFormRules = {
    storeCode: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('warehouseStore.selectStoreCode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (this.form?.storeId && this.form?.warehouseId) {
            warehouseStoreService
              .checkRepeat(this.form?.storeId, this.form?.warehouseId)
              .then((isRepeat: boolean) => {
                if (isRepeat) {
                  callback(translation('warehouseStore.storeCodeRepeat'));
                  return;
                }
                callback();
              })
              .catch((error: any) => {
                callback(error);
              });
          }
        },
        trigger: 'blur'
      }
    ],
    warehouseCode: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('warehouseStore.selectWarehouseCode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    this.operationType = 'add';
    this.title = 'warehouseStore.title';
    Object.assign(this.form, {
      storeId: null,
      storeCode: '',
      storeName: '',
      warehouseId: null,
      warehouseCode: '',
      warehouseName: ''
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  public openSelectWarehouseDialog(): void {
    this.selectWarehouseVisible = true;
  }
  public openSelectStroeDialog(): void {
    this.selectStoreLocationVisible = true;
  }
  public handleSelectedWarehouse(obj: any): void {
    this.form.warehouseId = obj.id;
    this.form.warehouseCode = obj.code;
    this.form.warehouseName = obj.name;
  }
  public handleSelectedStore(obj: any): void {
    this.form.storeId = obj.id;
    this.form.storeCode = obj.code;
    this.form.storeName = obj.name;
  }

  public onSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      console.log(valid);
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await warehouseStoreService.post({ ...this.form } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await warehouseStoreService.put({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
